import { GOUVERNORATS } from '../geojson/tunisie_gouvernorats';
import { DELEGATIONS } from '../geojson/tunisie_delegations';
import { LOCALITES } from '../geojson/tunisie_localites_part_2';

function globalController($localStorage, $state, $scope, $rootScope, AppSettings, WS, $window, $http, $translate, $uibModal, $location, $compile) {
    'ngInject';
    const vm = this;
    vm.actifSS = {};
    vm.global_filter = false;
    vm.settings = AppSettings;
    //vm.currentLang = AppSettings.lang;
    vm.managedObj = [];
    vm.managedObjFRS = [];
    vm.currentLang = $translate.proposedLanguage()

    vm.mapstunisie_latitude = '34.770981';
    vm.mapstunisie_longitude = '10.702299';

    //console.log(GOUVERNORATS);
    //console.log('DELEGATIONS', DELEGATIONS);
    if ($localStorage.user) {
        vm.user = $localStorage.user;
    }
    if ($localStorage.dashboard) {
        vm.dashboard = $localStorage.dashboard;
    } else {
        vm.dashboard = 'vente';
    }
    if ($localStorage.role) {
        vm.role = $localStorage.role;
    }

    var now = new Date();
    var annee = now.getFullYear();
    var mois = (now.getMonth() + 1) < 10 ? '0' + (now.getMonth() + 1) : (now.getMonth() + 1);
    var jour = now.getDate() < 10 ? '0' + now.getDate() : now.getDate();
    var heure = now.getHours();
    var minute = now.getMinutes();
    var seconde = now.getSeconds();

    var tab_mois = new Array(
        "Janvier", "Fevrier", "Mars",
        "Avril", "Mai", "Juin",
        "Juillet", "Aout", "Septembre",
        "Octobre", "Novembre", "Decembre"
    );


    vm.aujourdhui = jour + "/" + mois;
    vm.today = now.getDate();
    vm.todayDate = moment().format('YYYY-MM-DD');
    vm.mois = tab_mois[now.getMonth()];
    vm.moissuivant = tab_mois[now.getMonth() + 1];
    vm.moisprecedent = tab_mois[now.getMonth() - 1];
    vm.month = moment().format('M');
    vm.year = moment().format('YYYY');
    vm.yearMonth = moment().format('YYYY-MM');


    WS.get('devices/currentDevise').then(
        function (response) {
            vm.currentDevise = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log(error);
        }
    );


    WS.get('dashboard/echeances').then(
        function (response) {
            vm.echeances = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log(error);
        }
    );

    WS.get('dashboard/factures').then(
        function (response) {
            vm.factures = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log(error);
        }
    );


    WS.get('clients/nvClients').then(
        function (response) {
            vm.nvclients = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log(error);
        }
    );


    WS.get('mouvements/dc').then(
        function (response) {
            vm.mouvements = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log(error);
        }
    );

    WS.get('dashboard/commandes').then(
        function (response) {
            vm.commandes = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log(error);
        }
    );

    WS.get('dashboard/demandesAvoirs').then(
        function (response) {
            vm.demandes = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log(error);
        }
    );
    WS.get('dashboard/demande_conge').then(
        function (response) {
            vm.demande_conges = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log(error);
        }
    );

    WS.get('dashboard/plafond_clients').then(
        function (response) {
            vm.plafond_clients = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log(error);
        }
    );



    WS.get('dashboard/credits').then(
        function (response) {
            vm.credits = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log(error);
        }
    );

    WS.getScache('notes/getAllNotesNotification')
        .then(function (response) {
            vm.notesnav = response.data;
            $scope.$apply();
        })
        .then(null, function (error) {
            console.log(error);
        });

    WS.getScache('notes/getCountNonVu')
        .then(function (response) {
            vm.countnotesnav = response.data;
            $scope.$apply();
        })
        .then(null, function (error) {
            console.log(error);
        });

    WS.getScache('dashboard/visiteHorsClient')
        .then(function (response) {
            vm.visiteHorsClient = response.data;
            $scope.$apply();
        })
        .then(null, function (error) {
            console.log(error);
        });

    WS.getScache('soussocietes/GlobalfilterSousSociete')
        .then(function (response) {
            vm.affect_ss = response.data;


            _.each(vm.affect_ss.all_soussocietes, (element, index) => {

                if (element.temporaire == null) {
                    element.temporaire = 0;
                    vm.affect_ss.all_soussocietes[index].temporaire = 0;
                }
                if (element.actif == null) {
                    element.actif = 0;
                    vm.affect_ss.all_soussocietes[index].actif = 0;

                }
                vm.managedObj.push({
                    soussociete_code: element.code,
                    actif: element.actif
                });
            });
            //console.log('vm.managedObj', vm.managedObj)
            $scope.$apply();


            $(function () {

                $('.button-checkbox-glob-ss').each(function () {

                    // Settings
                    var $widget = $(this),
                        $button = $widget.find('button'),
                        $checkbox = $widget.find('input:checkbox'),
                        color = $button.data('color'),
                        settings = {
                            on: {
                                icon: 'glyphicon glyphicon-check'
                            },
                            off: {
                                icon: 'glyphicon glyphicon-unchecked'
                            }
                        };

                    // Event Handlers
                    $button.on('click', function (event) {
                        $checkbox.prop('checked', !$checkbox.is(':checked'));
                        $checkbox.triggerHandler('change');

                        const id = $(this).attr('id');
                        updateDisplay(id);
                    });
                    $checkbox.on('change', function () {
                        const id = $(this).attr('id');
                        updateDisplay(id);
                    });

                    // Actions
                    function updateDisplay(id) {
                        var isChecked = $checkbox.is(':checked');
                        if (id != undefined) {
                            if (isChecked) {
                                vm.actifSS[id] = 1;
                            } else {
                                vm.actifSS[id] = 0;
                            }
                            //console.log(vm.auths[id]);
                            //console.log(vm.auths);
                        }
                        // Set the button's state
                        $button.data('state', (isChecked) ? "on" : "off");

                        // Set the button's icon
                        $button.find('.state-icon')
                            .removeClass()
                            .addClass('state-icon ' + settings[$button.data('state')].icon);

                        // Update the button's color
                        if (isChecked) {
                            $button
                                .removeClass('btn-default')
                                .addClass('btn-' + color + ' active');
                        } else {
                            $button
                                .removeClass('btn-' + color + ' active')
                                .addClass('btn-default');
                        }
                    }


                    // Initialization
                    function init() {

                        updateDisplay();

                        // Inject the icon if applicable
                        if ($button.find('.state-icon').length == 0) {
                            $button.prepend('<i class="state-icon ' + settings[$button.data('state')].icon + '"></i> ');
                        }
                    }
                    init();
                });
            });


        })
        .then(null, function (error) {
            console.log(error);
        });


    WS.getScache('fournisseurs/GlobalfilterFournisseur')
        .then(function (response) {
            vm.affect_frs = response.data;


            _.each(vm.affect_frs.all_fournisseurs, (element, index) => {

                if (element.temporaire == null) {
                    element.temporaire = 0;
                    vm.affect_frs.all_fournisseurs[index].temporaire = 0;
                }
                if (element.actif == null) {
                    element.actif = 0;
                    vm.affect_frs.all_fournisseurs[index].actif = 0;

                }
                vm.managedObjFRS.push({
                    fournisseur_code: element.code,
                    actif: element.actif
                });
            });

            $scope.$apply();


            $(function () {

                $('.button-checkbox-glob-frs').each(function () {

                    // Settings
                    var $widget = $(this),
                        $button = $widget.find('button'),
                        $checkbox = $widget.find('input:checkbox'),
                        color = $button.data('color'),
                        settings = {
                            on: {
                                icon: 'glyphicon glyphicon-check'
                            },
                            off: {
                                icon: 'glyphicon glyphicon-unchecked'
                            }
                        };

                    // Event Handlers
                    $button.on('click', function (event) {
                        $checkbox.prop('checked', !$checkbox.is(':checked'));
                        $checkbox.triggerHandler('change');

                        const id = 'frs_' + $(this).attr('id');
                        updateDisplay(id);
                    });
                    $checkbox.on('change', function () {
                        const id = 'frs_' + $(this).attr('id');
                        updateDisplay(id);
                    });

                    // Actions
                    function updateDisplay(id) {
                        var isChecked = $checkbox.is(':checked');
                        if (id != undefined) {
                            if (isChecked) {
                                vm.actifFRS[id] = 1;
                            } else {
                                vm.actifFRS[id] = 0;
                            }
                            //console.log(vm.auths[id]);
                            //console.log(vm.auths);
                        }
                        // Set the button's state
                        $button.data('state', (isChecked) ? "on" : "off");

                        // Set the button's icon
                        $button.find('.state-icon')
                            .removeClass()
                            .addClass('state-icon ' + settings[$button.data('state')].icon);

                        // Update the button's color
                        if (isChecked) {
                            $button
                                .removeClass('btn-default')
                                .addClass('btn-' + color + ' active');
                        } else {
                            $button
                                .removeClass('btn-' + color + ' active')
                                .addClass('btn-default');
                        }
                    }


                    // Initialization
                    function init() {

                        updateDisplay();

                        // Inject the icon if applicable
                        if ($button.find('.state-icon').length == 0) {
                            $button.prepend('<i class="state-icon ' + settings[$button.data('state')].icon + '"></i> ');
                        }
                    }
                    init();
                });
            });


        })
        .then(null, function (error) {
            console.log(error);
        });


    /**
     * check expiration compte
     */


    vm.expiration = false;
    vm.expirationErrorContrat = false;
    vm.alertExpiration = '';
    vm.support_application = null;
    vm.gestionStock = '0';
    vm.gestionCommande = '0';
    vm.merchandising = '0';
    WS.getData('societes')
        .then(function (response) {
            vm.societes = response.data[0];
            var contrat_code = vm.societes.contrat;
            /*console.log(response);
            console.log(contrat_code);*/
            $http({
                method: 'GET',
                url: `https://api.sav.priorityexpeditions.com/api/v1/license/${contrat_code}`,
                //url: `https://api.sav.priorityexpeditions.com/api/v1/license/nomadis`,
                cache: false
            }).then(function successCallback(response) {
                //console.log($.isEmptyObject(response.data));
                //console.log('-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_Contrat_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-');

                vm.support_application = response.data.support;
                vm.linkBi = response.data.url_bi;
                vm.gestionStock = response.data.gestion_stock;
                vm.global_gestion_stock = response.data.gestion_stock;
                vm.gestionCommande = response.data.gestion_commandes;
                vm.merchandising = response.data.merchandising;
                vm.url_pub = response.data.url_pub;
                vm.texte_pub = response.data.texte_pub;
                vm.debut_license = response.data.date_debut;
                vm.fin_license = response.data.date_fin;
                vm.gestionStock = '1';
                vm.global_gestion_stock = '1';
                vm.gestionCommande = '1';
                vm.merchandising = '1';

                vm.existBi = (vm.linkBi) ? true : false;
                if ($.isEmptyObject(response.data) == true) {
                    vm.expiration = true;
                    vm.expirationErrorContrat = true;
                    vm.alertExpiration = `Erreur de vérification de compte, veuillez contacter l'admin.`;
                } else {
                    //response.isactif="0";
                    if (response.data.isactif == "1") {
                        var currentday = new moment();
                        var lastday = moment(response.data.date_fin, "YYYY-MM-DD");
                        //Difference in number of days
                        var restime = moment.duration(lastday.diff(currentday)).asDays();
                        var restime = Number(restime.toFixed(0));
                        //console.log(restime);
                        //restime = 0;
                        if (restime < 7 && restime > 0) {
                            vm.expiration = true;
                            vm.alertExpiration = `Il Vous reste ${restime} jours avant l'expiration de votre compte.`;
                        }
                        if (restime <= 0) {
                            vm.expiration = true;
                            vm.alertExpiration = `Votre compte a été expiré.`;
                        }
                    }
                    //console.log('-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_Contrat_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-');
                    else {
                        vm.expiration = true;
                        vm.alertExpiration = `Votre compte a été désactivé.`;
                    }
                    //console.log('-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_Contrat_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-');
                }
            }, function errorCallback(response) {
                vm.expiration = true;
                vm.expirationErrorContrat = true;
                vm.alertExpiration = `Erreur de verification de compte, veuillez contacter l'admin.`;
            });



            /**
             * get message
             */
            $http({
                method: 'GET',
                url: `https://api.sav.priorityexpeditions.com/api/v1/sendMessages/${contrat_code}`,
                //url: `http://127.0.0.1:80/clediss/gestion_projet/gestion_projet/public/api/v1/sendMessages/nomadis`,
                cache: false
            }).then(function successCallback(response) {
                // console.log('response')
                // console.log(response.data)
                // console.log('response')
                vm.message_bottom_exists = true;
                vm.background_message = response.data.background;
                vm.color_message = response.data.color;
                vm.bottom_message = response.data.message;

            }, function errorCallback(response) {
                console.log('errrooooor', response)
            });
            $scope.$apply();
        })
        .then(null, function (error) {
            console.log(error);
        });

    //var contrat_code = 'nomadis';

    vm.changeCurrentLang = () => {
        // console.log(vm.currentLang);
        $translate.use(vm.currentLang);
    };

    vm.applyFilterSS = (actif, soussociete_code) => {
        actif = (actif == 1) ? 0 : 1;
        // console.log(actif, soussociete_code);

        var obj = { soussociete_code, actif };

        vm.managedObj = _.reject(vm.managedObj, function (element) { return element.soussociete_code == soussociete_code; });

        vm.managedObj.push(obj);
        // console.log('vm.managedObj');
        // console.log(vm.managedObj);
        // console.log('--------');
    };

    vm.storeAllAffectation = () => {
        var data = {
            affectations: vm.managedObj
        }

        WS.post('soussocietes/manage_affectationSS', data).then(
            (response) => {
                if (response.data.success) {
                    toastr.success($translate.instant('affectation effectué avec succés'));
                    $window.location.reload();
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }


    vm.applyFilterFRS = (actif, fournisseur_code) => {
        actif = (actif == 1) ? 0 : 1;
        // console.log(actif, fournisseur_code);

        var obj = { fournisseur_code, actif };

        vm.managedObjFRS = _.reject(vm.managedObjFRS, function (element) { return element.fournisseur_code == fournisseur_code; });

        vm.managedObjFRS.push(obj);
        // console.log('vm.managedObj');
        // console.log(vm.managedObj);
        // console.log('--------');
    };

    vm.storeAllAffectationFRS = () => {
        var data = {
            affectations: vm.managedObjFRS
        }

        WS.post('fournisseurs/manage_affectationFRS', data).then(
            (response) => {
                if (response.data.success) {
                    toastr.success($translate.instant('affectation effectué avec succés'));
                    $window.location.reload();
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }


    vm.menus = [
        'POWERBI', 'DASHBOARD',
        'STAT-CHARGEMENTS_CHARGEMENTS',
        'INDEX_CHARGEMENTS',
        'INDEX_CLIENTS',
        'INDEX_BL',
        'INDEX_KPI',
        'INDEX_PLANDECHARGES',
        'INDEX_GESTIONCOMMANDES',
        'INDEX_VISITES',
        'INDEX_ROUTINGS',
        'LISTUSERS_USERS',
        'INDEX_PRODUITS',
        'INDEX_RAPPORTS',
        'RAPPORTDN_ANALYSES',
        'INDEX_CHARGES',
        'INDEX_OBJECTIFS',
        'INDEX_FIDELITES',
        'INDEX_STOCKDEPOT',
        'INDEX_ACHAT',
        'INDEX_CAISSES',
        'INDEX_NOTES',
        'INDEX_CARBURANTS',
        'INDEX_KILOMETRAGES',
        'INDEX_STOCKS',
        'INDEX_RAPPORTSVISITE',
        'INDEX_CONSIGNES',
        'INDEX_INTEGRATIONERP',
        'INDEX_ASSORTIMENTS',

    ]
    WS.get('modules/get_Liste_Affichage').then(
        (response) => {

            vm.list_menu = response.data;

            if (vm.list_menu.length > 0) {
                _.each(vm.menus, (element, index) => {
                    console.log(element, vm.list_menu.includes(element))
                    if (!vm.list_menu.includes(element)) {
                        $('.' + element).hide();
                        $('.' + element).remove();
                        console.log(element, 'hideed')
                    }
                });
            }

        })


    $(document).on('click', function (evt) {
        clearTimeout(vm.timeOutVar);
        vm.timeOutVar = setTimeout(function () {
            console.log('redirect to login')
            $localStorage.$reset();
            $http.get(AppSettings.apiUrl + 'logout').then((response) => {

                console.log(response);

            }, ((err) => {
                console.log(err);
            }));
            $state.go('access.login', {}, { reload: true });
        }, 60 * 60 * 1000);
    });





    /**
     * ZONING
     */
    vm.openModalZonning = () => {
        vm.modal_zonning = $uibModal.open({
            animation: true,
            templateUrl: 'modalZonning',
            size: 'lg',
            backdrop: true,
            windowClass: 'modal-zonning',
            controller: function ($scope, $translate) {
                $scope.input_tab_clients_hors_polygon = {};
                $scope.tab_clients_hors_polygon = {};
                $scope.stock_all_layers = [];
                $scope.stock_all_markers = [];
                $scope.layers_json = [];
                $scope.layers_params = [];
                $scope.distance_mesure = {};
                $scope.filter_tournee = {};
                $scope.current_date = moment().format('YYYY-MM-DD');
                $scope.center_latitude = vm.mapstunisie_latitude;
                $scope.center_longitude = vm.mapstunisie_longitude;

                $scope.application = 'Nomadis';
                $scope.closeZonning = () => {
                    console.log('panel_closed')
                    vm.modal_zonning.close();
                }

                $scope.current = false;

                $scope.selected_gouvernorat = [];
                $scope.selected_delegation = [];

                $scope.changeFilterByType = (type_tournee) => {

                    $scope.filter_tournee.route_code = '';
                    $scope.filter_tournee.tournee_code = '';
                    $scope.filter_tournee.gouvernorat_code = '';
                    $scope.filter_tournee.delegation_code = '';
                    $scope.filter_tournee.localite_code = '';
                    $scope.filter_tournee.region_code = '';
                    $scope.filter_tournee.zone_code = '';
                    $scope.filter_tournee.secteur_code = '';

                    if (type_tournee == 'routing' || type_tournee == 'tournee') {
                        $scope.selectRoutes(false);
                    }
                    if (type_tournee == 'gouvernorat' || type_tournee == 'delegation' || type_tournee == 'localite') {
                        $scope.selectGouvernorats(false);
                    }

                    if (type_tournee == 'region' || type_tournee == 'zone' || type_tournee == 'secteur') {
                        $scope.selectRegions(false);
                    }
                }

                $scope.selectTypeTournee = () => {

                    if (type_tournee == 'routing' || type_tournee == 'tournee') {
                    }

                    if (type_tournee == 'gouvernorat' || type_tournee == 'delegation' || type_tournee == 'localite') {
                        $scope.selectGouvernorats(false);
                    }

                    if (type_tournee == 'region' || type_tournee == 'zone' || type_tournee == 'secteur') {
                        $scope.selectRegions(false);
                    }
                }


                $scope.selectGouvernorats = (withMaps = true) => {
                    if (withMaps) {
                        $scope.current = false;
                        $scope.current_code = null;
                        $scope.current_libelle = '';
                    }
                    $scope.list_clients = [];
                    WS.getData('gouvernorats/selectWithNbClients', {application: $scope.application})
                        .then(function (response) {
                            if (response.status == 200) {
                                $scope.gouvernorats = response.data;
                                if (withMaps) {
                                    $scope.initMapsGouvernorats();
                                    if ($scope.current_element && $scope.current_code != null) {
                                        $scope.toggleStreet($scope.current_element, null, 'gouvernorat');
                                    }
                                }
                                $scope.$apply();
                            } else { }
                        })
                        .then(null, function (error) {
                            console.log(error);
                        });
                }
                $scope.selectGouvernorats();
                $scope.selectRegions = (withMaps = true) => {
                    if (withMaps) {
                        $scope.current = false;
                        $scope.current = false;
                        $scope.current_code = null;
                        $scope.current_libelle = '';
                    }

                    $scope.list_clients = [];
                    WS.getData('regions/selectWithNbClients')
                        .then(function (response) {
                            if (response.status == 200) {
                                $scope.regions = response.data;
                                $scope.$apply();
                                if (withMaps) {
                                    $scope.initMapsRegions();
                                    if ($scope.current_element && $scope.current_code != null) {
                                        $scope.toggleStreet($scope.current_element, null, 'region');
                                    }
                                }

                            } else { }
                        })
                        .then(null, function (error) {
                            console.log(error);
                        });
                }

                $scope.initMapsRegions = () => {
                    console.log('init initMapsRegions')
                    $scope.current = false;
                    $scope.current_code = null;
                    $scope.current_libelle = '';
                    $scope.center_latitude = vm.mapstunisie_latitude;
                    $scope.center_longitude = vm.mapstunisie_longitude;
                    $('#container_regions').empty();
                    $('#container_regions').append('<div style="width: 100%; height: 640px" id="maps_regions"></div>');

                    $scope.osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
                    $scope.osmAttrib = '&copy; <a href="http://nomadis.online/">Nomadis</a>';
                    $scope.osm = L.tileLayer($scope.osmUrl, {
                        maxZoom: 18,
                        attribution: $scope.osmAttrib
                    });
                    //console.log('from maps : ', $scope.getpays)
                    $scope.map = new L.Map('maps_regions', {
                        center: new L.LatLng($scope.center_latitude, $scope.center_longitude),
                        zoom: 11,
                        fullscreenControl: true,
                        fullscreenControlOptions: {
                            position: 'topright'
                        }
                    });

                    $scope.osm.addTo($scope.map);
                    $scope.clearLayers();
                    //add poligones : 
                    _.each($scope.regions, (element, index) => {
                        //console.log(element);
                        if (element.code != null && element.layer != null) {

                            $scope.paramsdrawitem = {};
                            $scope.paramsdrawitem.id = element.id;
                            $scope.paramsdrawitem.code = element.code;
                            $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : '#3388ff';
                            $scope.paramsdrawitem.onEachFeature = function (feature, layer) {
                                layer.bindPopup('<pre>' + element.code + ' - ' + element.libelle + '</pre>');
                                //layer._leaflet_id = element.id;
                            }
                            $scope.current_layer = JSON.parse(element.layer);
                            // $scope.current_layer._leaflet_id = element.id;
                            $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                            $scope.stock_all_layers.push($scope.drawnItems);
                            $scope.layers_json.push($scope.current_layer);
                            $scope.layers_params.push($scope.paramsdrawitem);


                            if (!$scope.bounds) {
                                $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                            } else {
                                $scope.bounds.extend($scope.drawnItems.getBounds());
                            }
                        }
                    })
                    $scope.map.fitBounds($scope.bounds);
                    setTimeout(function () {
                        $scope.map.invalidateSize();
                        console.log('initialized');
                    }, 3000);
                }

                $scope.selectZonesByRegion = (region_code = null, withMaps = true) => {

                    region_code = region_code ? region_code : $scope.region_code;
                    $scope.selected_region = [];
                    $scope.current_libelle = '';
                    WS.getData('zones/selectZonesByRegion', { region_code })
                        .then(function (response) {
                            if (response.status == 200) {
                                $scope.zones = response.data;
                                $scope.selected_region = _.filter($scope.regions, function (reg) { return reg.code == region_code; });
                                $scope.$apply();
                                if (withMaps) {
                                    $scope.initMapsZones();
                                }
                            } else { }
                        })
                        .then(null, function (error) {
                            console.log(error);
                        });
                }

                $scope.initMapsZones = () => {
                    $scope.current = false;
                    $scope.current_code = null;
                    $scope.current_libelle = '';
                    console.log('init initMapsZones')
                    // $scope.stock_all_layers = [];
                    // $scope.layers_json = [];
                    // $scope.layers_params = [];
                    $scope.center_latitude = vm.mapstunisie_latitude;
                    $scope.center_longitude = vm.mapstunisie_longitude;
                    $('#container_zones').empty();
                    $('#container_zones').append('<div style="width: 100%; height: 640px" id="maps_zones"></div>');

                    $scope.osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
                    $scope.osmAttrib = '&copy; <a href="http://nomadis.online/">Nomadis</a>';
                    $scope.osm = L.tileLayer($scope.osmUrl, {
                        maxZoom: 18,
                        attribution: $scope.osmAttrib
                    });
                    //console.log('from maps : ', $scope.getpays)
                    $scope.map = new L.Map('maps_zones', {
                        center: new L.LatLng($scope.center_latitude, $scope.center_longitude),
                        zoom: 11,
                        fullscreenControl: true,
                        fullscreenControlOptions: {
                            position: 'topright'
                        }
                    });

                    $scope.osm.addTo($scope.map);
                    $scope.clearLayers();
                    //current gouvernorat
                    _.each($scope.selected_gouvernorat, (element, index) => {
                        if (element.layer) {
                            $scope.paramsdrawitem = {};
                            $scope.paramsdrawitem.id = element.id;
                            $scope.paramsdrawitem.code = element.code;
                            $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : '#3388ff';
                            $scope.paramsdrawitem.onEachFeature = function (feature, layer) {
                                layer.bindPopup('<pre>' + element.code + ' - ' + element.libelle + '</pre>');
                                //layer._leaflet_id = element.id;
                            }
                            $scope.current_layer = JSON.parse(element.layer);
                            // $scope.current_layer._leaflet_id = element.id;
                            $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                            $scope.stock_all_layers.push($scope.drawnItems);
                            $scope.layers_json.push($scope.current_layer);
                            $scope.layers_params.push($scope.paramsdrawitem);


                            if (index == 0) {
                                $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                            } else {
                                $scope.bounds.extend($scope.drawnItems.getBounds());
                            }
                        }

                    })
                    //add poligones : 
                    _.each($scope.delegations, (element, index) => {
                        if (element.layer) {
                            $scope.paramsdrawitem = {};
                            $scope.paramsdrawitem.id = element.id;
                            $scope.paramsdrawitem.code = element.code;
                            $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : '#e50202';
                            $scope.paramsdrawitem.onEachFeature = function (feature, layer) {
                                layer.bindPopup('<pre>' + element.code + ' - ' + element.libelle + '</pre>');
                                //layer._leaflet_id = element.id;
                            }
                            $scope.current_layer = JSON.parse(element.layer);
                            // $scope.current_layer._leaflet_id = element.id;
                            $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                            $scope.stock_all_layers.push($scope.drawnItems);
                            $scope.layers_json.push($scope.current_layer);
                            $scope.layers_params.push($scope.paramsdrawitem);


                            if (index == 0) {
                                $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                            } else {
                                $scope.bounds.extend($scope.drawnItems.getBounds());
                            }
                        }

                    })



                    $scope.map.fitBounds($scope.bounds);
                    setTimeout(function () {
                        $scope.map.invalidateSize();
                        console.log('initialized');
                    }, 3000);


                    //get all client by gouvernorat
                    console.log('$scope.stock_all_layers', $scope.stock_all_layers)

                    $scope.current = true;
                    $scope.getListClientBySearch('gouvernorat', $scope.gouvernorat_code, 'delegation');
                }

                $scope.initMapsGouvernorats = (id_maps = 'gouvernorats') => {
                    console.log('init initMapsGouvernorats')
                    $scope.stock_all_layers = [];
                    $scope.layers_json = [];
                    $scope.layers_params = [];
                    $scope.center_latitude = vm.mapstunisie_latitude;
                    $scope.center_longitude = vm.mapstunisie_longitude;
                    $(`#container_${id_maps}`).empty();
                    $(`#container_${id_maps}`).append(`<div style="width: 100%; height: 640px" id="maps_${id_maps}"></div>`);

                    $scope.osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
                    $scope.osmAttrib = '&copy; <a href="http://nomadis.online/">Nomadis</a>';
                    $scope.osm = L.tileLayer($scope.osmUrl, {
                        maxZoom: 18,
                        attribution: $scope.osmAttrib
                    });
                    //console.log('from maps : ', $scope.getpays)
                    $scope.map = new L.Map(`maps_${id_maps}`, {
                        center: new L.LatLng($scope.center_latitude, $scope.center_longitude),
                        zoom: 11,
                        fullscreenControl: true,
                        fullscreenControlOptions: {
                            position: 'topright'
                        }
                    });

                    $scope.osm.addTo($scope.map);
                    $scope.clearLayers();
                    //add poligones : 
                    _.each($scope.gouvernorats, (element, index) => {
                        //console.log(element);
                        if (element.code != null) {

                            $scope.paramsdrawitem = {};
                            $scope.paramsdrawitem.id = element.id;
                            $scope.paramsdrawitem.code = element.code;
                            $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : '#3388ff';
                            $scope.paramsdrawitem.onEachFeature = function (feature, layer) {
                                layer.bindPopup('<pre>' + element.code + ' - ' + element.libelle + '</pre>');
                                //layer._leaflet_id = element.id;
                            }
                            $scope.current_layer = JSON.parse(element.layer);
                            // $scope.current_layer._leaflet_id = element.id;
                            $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                            $scope.stock_all_layers.push($scope.drawnItems);
                            $scope.layers_json.push($scope.current_layer);
                            $scope.layers_params.push($scope.paramsdrawitem);


                            if (!$scope.bounds) {
                                $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                            } else {
                                $scope.bounds.extend($scope.drawnItems.getBounds());
                            }
                        }
                    })
                    $scope.map.fitBounds($scope.bounds);
                    setTimeout(function () {
                        $scope.map.invalidateSize();
                        console.log('initialized');
                    }, 3000);
                }

                $scope.selectDelegationByGouvernorat = (gouvernorat_code = null, withMaps = true) => {
                    gouvernorat_code = gouvernorat_code ? gouvernorat_code : $scope.gouvernorat_code;
                    $scope.selected_gouvernorat = [];
                    WS.getData('delegations/selectDelegationByGouvernorat', { application: $scope.application, gouvernorat_code })
                        .then(function (response) {
                            if (response.status == 200) {
                                $scope.delegations = response.data;
                                $scope.selected_gouvernorat = _.filter($scope.gouvernorats, function (gouv) { return gouv.code == gouvernorat_code; });
                                $scope.$apply();
                                if (withMaps) {
                                    $scope.initMapsDelegations();
                                }
                            } else { }
                        })
                        .then(null, function (error) {
                            console.log(error);
                        });
                }

                $scope.initMapsDelegations = () => {
                    console.log('init initMapsDelegations')
                    $scope.stock_all_layers = [];
                    $scope.layers_json = [];
                    $scope.layers_params = [];
                    $scope.center_latitude = vm.mapstunisie_latitude;
                    $scope.center_longitude = vm.mapstunisie_longitude;
                    $('#container_delegations').empty();
                    $('#container_delegations').append('<div style="width: 100%; height: 640px" id="maps_delegations"></div>');

                    $scope.osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
                    $scope.osmAttrib = '&copy; <a href="http://nomadis.online/">Nomadis</a>';
                    $scope.osm = L.tileLayer($scope.osmUrl, {
                        maxZoom: 18,
                        attribution: $scope.osmAttrib
                    });
                    //console.log('from maps : ', $scope.getpays)
                    $scope.map = new L.Map('maps_delegations', {
                        center: new L.LatLng($scope.center_latitude, $scope.center_longitude),
                        zoom: 11,
                        fullscreenControl: true,
                        fullscreenControlOptions: {
                            position: 'topright'
                        }
                    });

                    $scope.osm.addTo($scope.map);
                    $scope.clearLayers();
                    //current gouvernorat
                    _.each($scope.selected_gouvernorat, (element, index) => {
                        if (element.layer) {
                            $scope.paramsdrawitem = {};
                            $scope.paramsdrawitem.id = element.id;
                            $scope.paramsdrawitem.code = element.code;
                            $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : '#3388ff';
                            $scope.paramsdrawitem.onEachFeature = function (feature, layer) {
                                layer.bindPopup('<pre>' + element.code + ' - ' + element.libelle + '</pre>');
                                //layer._leaflet_id = element.id;
                            }
                            $scope.current_layer = JSON.parse(element.layer);
                            // $scope.current_layer._leaflet_id = element.id;
                            $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                            $scope.stock_all_layers.push($scope.drawnItems);
                            $scope.layers_json.push($scope.current_layer);
                            $scope.layers_params.push($scope.paramsdrawitem);


                            if (index == 0) {
                                $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                            } else {
                                $scope.bounds.extend($scope.drawnItems.getBounds());
                            }
                        }

                    })
                    //add poligones : 
                    _.each($scope.delegations, (element, index) => {
                        if (element.layer) {
                            $scope.paramsdrawitem = {};
                            $scope.paramsdrawitem.id = element.id;
                            $scope.paramsdrawitem.code = element.code;
                            $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : '#e50202';
                            $scope.paramsdrawitem.onEachFeature = function (feature, layer) {
                                layer.bindPopup('<pre>' + element.code + ' - ' + element.libelle + '</pre>');
                                //layer._leaflet_id = element.id;
                            }
                            $scope.current_layer = JSON.parse(element.layer);
                            // $scope.current_layer._leaflet_id = element.id;
                            $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                            $scope.stock_all_layers.push($scope.drawnItems);
                            $scope.layers_json.push($scope.current_layer);
                            $scope.layers_params.push($scope.paramsdrawitem);


                            if (index == 0) {
                                $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                            } else {
                                $scope.bounds.extend($scope.drawnItems.getBounds());
                            }
                        }

                    })



                    $scope.map.fitBounds($scope.bounds);
                    setTimeout(function () {
                        $scope.map.invalidateSize();
                        console.log('initialized');
                    }, 3000);


                    //get all client by gouvernorat
                    console.log('$scope.stock_all_layers', $scope.stock_all_layers)

                    $scope.current = true;
                    $scope.getListClientBySearch('gouvernorat', $scope.gouvernorat_code, 'delegation');
                }

                $scope.correctionGouvernorats = () => {
                    console.log(GOUVERNORATS.features);


                    WS.post('gouvernorats/correctionGouvernorats', { data: GOUVERNORATS.features })
                        .then(function (response) {
                            if (response.status == 200) {
                                swal(
                                    $translate.instant('Succès'),
                                    $translate.instant('Action effectué avec succès'),
                                    'success'
                                )
                                $scope.selectGouvernorats();
                                $scope.$apply();
                            } else { }
                        })
                        .then(null, function (error) {
                            console.log(error);
                        });
                }

                $scope.correctionDelegations = () => {
                    console.log(DELEGATIONS.features);

                    _.each(DELEGATIONS.features, (element, index) => {
                        WS.post('delegations/correctionDelegations', { data: element })
                            .then(function (response) {
                                if (response.status == 200) {
                                    swal(
                                        $translate.instant('Succès'),
                                        $translate.instant('Action effectué avec succès'),
                                        'success'
                                    )
                                    // $scope.selectDelegationByGouvernorat();
                                    $scope.$apply();
                                } else { }
                            })
                            .then(null, function (error) {
                                console.log(error);
                            });
                    })

                }

                $scope.correctionLocalites = () => {
                    console.log(LOCALITES.features);

                    _.each(LOCALITES.features, (element, index) => {
                        WS.post('localites/correctionLocalites', { data: element })
                            .then(function (response) {
                                if (response.status == 200) {
                                    swal(
                                        $translate.instant('Succès'),
                                        $translate.instant('Action effectué avec succès'),
                                        'success'
                                    )
                                    // $scope.selectDelegationByGouvernorat();
                                    $scope.$apply();
                                } else { }
                            })
                            .then(null, function (error) {
                                console.log(error);
                            });
                    })

                }

                $scope.correctionNbrPdvGouvernorat = () => {
                    WS.post('gouvernorats/setNbrPdv')
                    .then(function (response) {
                        if (response.data.success == true) {

                            swal(
                                'Succès',
                                'Action effectuée avec succès',
                                'success'
                            );
                            $scope.$apply();

                        } else if (response.data.success == false) {
                            swal('Error', 'Oups ', 'error');
                        }
                    })
                    .then(null, function (error) {
                        console.log(error);
                    });
                }

                $scope.correctionNbrPdvDelegation = () => {
                    WS.post('delegations/setNbrPdv')
                    .then(function (response) {
                        if (response.data.success == true) {

                            swal(
                                'Succès',
                                'Action effectuée avec succès',
                                'success'
                            );
                            $scope.$apply();

                        } else if (response.data.success == false) {
                            swal('Error', 'Oups ', 'error');
                        }
                    })
                    .then(null, function (error) {
                        console.log(error);
                    });
                }

                $scope.correctionNbrPdvLocalite = () => {
                    WS.post('localites/setNbrPdv')
                    .then(function (response) {
                        if (response.data.success == true) {

                            swal(
                                'Succès',
                                'Action effectuée avec succès',
                                'success'
                            );
                            $scope.$apply();

                        } else if (response.data.success == false) {
                            swal('Error', 'Oups ', 'error');
                        }
                    })
                    .then(null, function (error) {
                        console.log(error);
                    });
                }

                $scope.selectLocalitesBySearch = () => {
                    $scope.selected_gouvernorat = [];
                    WS.getData('localites/selectLocalitesBySearch', { application: $scope.application, gouvernorat_code: $scope.gouvernorat_code, delegation_code: $scope.delegation_code })
                        .then(function (response) {
                            if (response.status == 200) {
                                $scope.localites = response.data;
                                $scope.selected_gouvernorat = _.filter($scope.gouvernorats, function (gouv) { return gouv.code == $scope.gouvernorat_code; });
                                $scope.selected_delegation = _.filter($scope.delegations, function (del) { return del.code == $scope.delegation_code; });
                                $scope.$apply();

                                $scope.initMapsLocalites();
                            } else { }
                        })
                        .then(null, function (error) {
                            console.log(error);
                        });
                }


                $scope.selectType = (column_name = 'gouvernorat', code) => {
                    $scope['selected_' + column_name] = _.filter($scope[column_name + 's'], function (gouv) { return gouv.code == code; });
                    console.log('selected_object_' + column_name, $scope['selected_' + column_name])
                }

                $scope.initMapsLocalites = () => {
                    console.log('init initMapsLocalites')
                    $scope.stock_all_layers = [];
                    $scope.layers_json = [];
                    $scope.layers_params = [];
                    $scope.center_latitude = vm.mapstunisie_latitude;
                    $scope.center_longitude = vm.mapstunisie_longitude;
                    $('#container_localites').empty();
                    $('#container_localites').append('<div style="width: 100%; height: 640px" id="maps_localites"></div>');

                    $scope.osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
                    $scope.osmAttrib = '&copy; <a href="http://nomadis.online/">Nomadis</a>';
                    $scope.osm = L.tileLayer($scope.osmUrl, {
                        maxZoom: 18,
                        attribution: $scope.osmAttrib
                    });
                    //console.log('from maps : ', $scope.getpays)
                    $scope.map = new L.Map('maps_localites', {
                        center: new L.LatLng($scope.center_latitude, $scope.center_longitude),
                        zoom: 11,
                        fullscreenControl: true,
                        fullscreenControlOptions: {
                            position: 'topright'
                        }
                    });

                    $scope.osm.addTo($scope.map);
                    $scope.clearLayers();
                    //current delegation
                    _.each($scope.selected_gouvernorat, (element, index) => {
                        $scope.paramsdrawitem = {};
                        $scope.paramsdrawitem.id = element.id;
                        $scope.paramsdrawitem.code = element.code;
                        $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : '#3388ff';
                        $scope.paramsdrawitem.onEachFeature = function (feature, layer) {
                            layer.bindPopup('<pre>' + element.code + ' - ' + element.libelle + '</pre>');
                            //layer._leaflet_id = element.id;
                        }
                        $scope.current_layer = JSON.parse(element.layer);
                        // $scope.current_layer._leaflet_id = element.id;
                        $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                        $scope.stock_all_layers.push($scope.drawnItems);
                        $scope.layers_json.push($scope.current_layer);
                        $scope.layers_params.push($scope.paramsdrawitem);


                        if (index == 0) {
                            $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                        } else {
                            $scope.bounds.extend($scope.drawnItems.getBounds());
                        }
                    })

                    //current delegation
                    _.each($scope.selected_delegation, (element, index) => {
                        $scope.paramsdrawitem = {};
                        $scope.paramsdrawitem.id = element.id;
                        $scope.paramsdrawitem.code = element.code;
                        $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : '#e50202';
                        $scope.paramsdrawitem.onEachFeature = function (feature, layer) {
                            layer.bindPopup('<pre>' + element.code + ' - ' + element.libelle + '</pre>');
                            //layer._leaflet_id = element.id;
                        }
                        $scope.current_layer = JSON.parse(element.layer);
                        // $scope.current_layer._leaflet_id = element.id;
                        $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                        $scope.stock_all_layers.push($scope.drawnItems);
                        $scope.layers_json.push($scope.current_layer);
                        $scope.layers_params.push($scope.paramsdrawitem);


                        if (index == 0) {
                            $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                        } else {
                            $scope.bounds.extend($scope.drawnItems.getBounds());
                        }
                    })
                    //add poligones : localites
                    _.each($scope.localites, (element, index) => {
                        $scope.paramsdrawitem = {};
                        $scope.paramsdrawitem.id = element.id;
                        $scope.paramsdrawitem.code = element.code;
                        $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : 'green';
                        $scope.paramsdrawitem.onEachFeature = function (feature, layer) {
                            layer.bindPopup('<pre>' + element.code + ' - ' + element.libelle + '</pre>');
                            //layer._leaflet_id = element.id;
                        }
                        $scope.current_layer = JSON.parse(element.layer);
                        // $scope.current_layer._leaflet_id = element.id;
                        $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                        $scope.stock_all_layers.push($scope.drawnItems);
                        $scope.layers_json.push($scope.current_layer);
                        $scope.layers_params.push($scope.paramsdrawitem);

                        if (index == 0) {
                            $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                        } else {
                            $scope.bounds.extend($scope.drawnItems.getBounds());
                        }
                    })

                    $scope.map.fitBounds($scope.bounds);
                    setTimeout(function () {
                        $scope.map.invalidateSize();
                        console.log('initialized');
                    }, 3000);
                }

                $scope.clearLayers = () => {
                    $scope.current = false;
                    console.log('Prep delete : $scope.stock_all_layers', $scope.stock_all_layers)
                    _.each($scope.stock_all_layers, layer => {
                        console.log('deleted layer =>', layer)
                        $scope.map.removeLayer(layer)
                    });

                    _.each($scope.stock_all_markers, marker => {
                        $scope.map.removeLayer(marker)
                    });
                    $scope.list_clients = [];
                    $scope.current_libelle = '';
                    // $scope.stock_all_markers
                    /*  $scope.stock_all_layers  = [];
                     $scope.stock_all_markers  = []; */
                    setTimeout(function () {
                        $scope.map.invalidateSize();
                        console.log('clearLayers initialized');
                    }, 2000);
                }

                $scope.appendLayers = (type = 'gouvernorat') => {
                    $scope.current = false;
                    $scope.clearLayers();

                    /* $scope.stock_all_layers = [];
                    $scope.layers_json = [];
                    $scope.layers_params = []; */
                    if (type == 'gouvernorat') {
                        _.each($scope.gouvernorats, (element, index) => {
                            if (element.code) {
                                console.log('appendLayers :', element)
                                $scope.paramsdrawitem.id = element.id;
                                $scope.paramsdrawitem.code = element.code;
                                $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : '#3388ff';
                                $scope.paramsdrawitem.onEachFeature = function (feature, layer) {
                                    layer.bindPopup('<pre>' + element.code + ' - ' + element.libelle + '</pre>');
                                }
                                $scope.current_layer = JSON.parse(element.layer);
                                $scope.current_layer._leaflet_id = element.id;
                                $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                                $scope.stock_all_layers.push($scope.drawnItems);
                                $scope.layers_json.push($scope.current_layer);
                                $scope.layers_params.push($scope.paramsdrawitem);


                                if (index == 0) {
                                    $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                                } else {
                                    $scope.bounds.extend($scope.drawnItems.getBounds());
                                }
                            }

                        })
                        $scope.map.fitBounds($scope.bounds);
                        //reinitialise draw for create new tournee
                        $scope.drawnItems = L.geoJson(null, $scope.paramsdrawitem).addTo($scope.map);
                    }

                    if (type == 'delegation') {
                        _.each($scope.selected_gouvernorat, (element, index) => {

                            if (element.code) {
                                $scope.current_element = $scope.selected_gouvernorat[0];
                                $scope.current_libelle = $scope.selected_gouvernorat[0].libelle;
                                $scope.current_layer = $scope.selected_gouvernorat[0].layer;
                                $scope.current_coordinates = $scope.selected_gouvernorat[0].coordinates;

                                console.log('appendLayers :', element)
                                $scope.paramsdrawitem.id = element.id;
                                $scope.paramsdrawitem.code = element.code;
                                $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : '#3388ff';
                                $scope.paramsdrawitem.onEachFeature = function (feature, layer) {
                                    layer.bindPopup('<pre>' + element.code + ' - ' + element.libelle + '</pre>');
                                }
                                $scope.current_layer = JSON.parse(element.layer);
                                $scope.current_layer._leaflet_id = element.id;
                                $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                                $scope.stock_all_layers.push($scope.drawnItems);
                                $scope.layers_json.push($scope.current_layer);
                                $scope.layers_params.push($scope.paramsdrawitem);


                                if (index == 0) {
                                    $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                                } else {
                                    $scope.bounds.extend($scope.drawnItems.getBounds());
                                }
                            }

                        })
                        $scope.map.fitBounds($scope.bounds);
                        //reinitialise draw for create new tournee
                        $scope.drawnItems = L.geoJson(null, $scope.paramsdrawitem).addTo($scope.map);

                        _.each($scope.delegations, (element, index) => {
                            if (element.code) {
                                console.log('appendLayers :', element)
                                $scope.paramsdrawitem.id = element.id;
                                $scope.paramsdrawitem.code = element.code;
                                $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : 'red';
                                $scope.paramsdrawitem.onEachFeature = function (feature, layer) {
                                    layer.bindPopup('<pre>' + element.code + ' - ' + element.libelle + '</pre>');
                                }
                                $scope.current_layer = JSON.parse(element.layer);
                                $scope.current_layer._leaflet_id = element.id;
                                $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                                $scope.stock_all_layers.push($scope.drawnItems);
                                $scope.layers_json.push($scope.current_layer);
                                $scope.layers_params.push($scope.paramsdrawitem);


                                if (index == 0) {
                                    $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                                } else {
                                    $scope.bounds.extend($scope.drawnItems.getBounds());
                                }
                            }

                        })
                        $scope.map.fitBounds($scope.bounds);
                        //reinitialise draw for create new tournee
                        $scope.drawnItems = L.geoJson(null, $scope.paramsdrawitem).addTo($scope.map);
                        $scope.getListClientBySearch('gouvernorat', $scope.gouvernorat_code, 'delegation');

                    }


                    if (type == 'zone') {
                        _.each($scope.selected_region, (element, index) => {

                            if (element.code && element.layer) {
                                $scope.current_element = $scope.selected_region[0];
                                $scope.current_libelle = $scope.selected_region[0].libelle;
                                $scope.current_layer = $scope.selected_region[0].layer;
                                $scope.current_coordinates = $scope.selected_region[0].coordinates;

                                console.log('appendLayers :', element)
                                $scope.paramsdrawitem.id = element.id;
                                $scope.paramsdrawitem.code = element.code;
                                $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : '#3388ff';
                                $scope.paramsdrawitem.onEachFeature = function (feature, layer) {
                                    layer.bindPopup('<pre>' + element.code + ' - ' + element.libelle + '</pre>');
                                }
                                $scope.current_layer = JSON.parse(element.layer);
                                $scope.current_layer._leaflet_id = element.id;
                                $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                                $scope.stock_all_layers.push($scope.drawnItems);
                                $scope.layers_json.push($scope.current_layer);
                                $scope.layers_params.push($scope.paramsdrawitem);


                                if (index == 0) {
                                    $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                                } else {
                                    $scope.bounds.extend($scope.drawnItems.getBounds());
                                }
                            }

                        })
                        $scope.map.fitBounds($scope.bounds);
                        //reinitialise draw for create new tournee
                        $scope.drawnItems = L.geoJson(null, $scope.paramsdrawitem).addTo($scope.map);

                        _.each($scope.zones, (element, index) => {
                            if (element.code && element.layer) {
                                console.log('appendLayers :', element)
                                $scope.paramsdrawitem.id = element.id;
                                $scope.paramsdrawitem.code = element.code;
                                $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : 'red';
                                $scope.paramsdrawitem.onEachFeature = function (feature, layer) {
                                    layer.bindPopup('<pre>' + element.code + ' - ' + element.libelle + '</pre>');
                                }
                                $scope.current_layer = JSON.parse(element.layer);
                                $scope.current_layer._leaflet_id = element.id;
                                $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                                $scope.stock_all_layers.push($scope.drawnItems);
                                $scope.layers_json.push($scope.current_layer);
                                $scope.layers_params.push($scope.paramsdrawitem);


                                if (index == 0) {
                                    $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                                } else {
                                    $scope.bounds.extend($scope.drawnItems.getBounds());
                                }
                            }

                        })
                        $scope.map.fitBounds($scope.bounds);
                        //reinitialise draw for create new tournee
                        $scope.drawnItems = L.geoJson(null, $scope.paramsdrawitem).addTo($scope.map);
                        $scope.getListClientBySearch('region', $scope.region_code, 'zone');

                    }
                }


                /**
                 * 
                 * @param {*} element : gouvernorat obj
                 * @param {*} key 
                 */
                $scope.toggleStreet = (element, key = null, column_name = 'gouvernorat', type_element = 'object', tournee = null) => {
                    console.log(element, column_name)

                    if (type_element == 'string') {
                        console.log('string', $scope[column_name + 's'])
                        var temp_arr = $scope[column_name + 's'].filter(elm => elm.code == element);
                        if (temp_arr.length > 0) {
                            element = temp_arr[0];
                        }
                    }
                    /*   if(column_name== 'delegation'){
                          $selected_gouvernorat[0].
                          element = $scope.gouvernorats.filter((gouv, index) => gouv.code == element);
                          element = element[0];
                      } */
                    /* if (speak) {
                        $window.speechSynthesis.speak(new SpeechSynthesisUtterance(element.libelle));
                    } */
                    // console.log(element)
                    var id_maps = tournee ? tournee : column_name;
                    $(`#container_${id_maps}s`).empty();
                    $(`#container_${id_maps}s`).append(`<div style="width: 100%; height: 640px" id="maps_${id_maps}s"></div>`);

                    $scope.osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
                    $scope.osmAttrib = '&copy; <a href="http://nomadis.online/">Nomadis</a>';
                    $scope.osm = L.tileLayer($scope.osmUrl, {
                        maxZoom: 18,
                        attribution: $scope.osmAttrib
                    });
                    //console.log('from maps : ', $scope.getpays)
                    $scope.map = new L.Map(`maps_${id_maps}s`, {
                        center: new L.LatLng($scope.center_latitude, $scope.center_longitude),
                        zoom: 11,
                        fullscreenControl: true,
                        fullscreenControlOptions: {
                            position: 'topright'
                        }
                    });

                    $scope.osm.addTo($scope.map);
                    $scope.clearLayers();
                    $scope.current = true;
                    $scope.stock_all_layers = [];
                    $scope.layers_json = [];
                    $scope.layers_params = [];


                    //append gouvernorat
                    if (column_name == 'delegation' || column_name == 'localite') {
                        $scope.current_element = $scope.selected_gouvernorat[0];
                        $scope.current_libelle = $scope.selected_gouvernorat[0].libelle;
                        $scope.current_layer = $scope.selected_gouvernorat[0].layer;
                        $scope.current_coordinates = $scope.selected_gouvernorat[0].coordinates;
                        // ---------------------------------------------------------------------------------------------------
                        if ($scope.selected_gouvernorat[0].layer) {

                            $scope.paramsdrawitem.id = $scope.selected_gouvernorat[0].id;
                            $scope.paramsdrawitem.code = $scope.selected_gouvernorat[0].code;
                            $scope.paramsdrawitem.color = ($scope.selected_gouvernorat[0].couleur) ? $scope.selected_gouvernorat[0].couleur : '#3388ff';
                            $scope.paramsdrawitem.onEachFeature = function (feature, layer) {
                                console.log("layer", layer);
                                console.log("layer", layer);
                                layer.bindPopup('<pre>' + $scope.selected_gouvernorat[0].id + ' - ' + $scope.selected_gouvernorat[0].libelle + ' - ' + '</pre>');
                                //layer._leaflet_id = $scope.selected_gouvernorat[0].id;
                            }
                            $scope.current_layer = JSON.parse($scope.selected_gouvernorat[0].layer);
                            $scope.current_layer._leaflet_id = $scope.selected_gouvernorat[0].id;
                            $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                            $scope.stock_all_layers.push($scope.drawnItems);
                            $scope.layers_json.push($scope.current_layer);
                            $scope.layers_params.push($scope.paramsdrawitem);


                        }
                    }

                    if (column_name == 'localite') {
                        $scope.current_element = $scope.selected_delegation[0];
                        $scope.current_libelle = $scope.selected_delegation[0].libelle;
                        $scope.current_layer = $scope.selected_delegation[0].layer;
                        $scope.current_coordinates = $scope.selected_delegation[0].coordinates;
                        // ---------------------------------------------------------------------------------------------------
                        if ($scope.selected_delegation[0].layer) {

                            $scope.paramsdrawitem.id = $scope.selected_delegation[0].id;
                            $scope.paramsdrawitem.code = $scope.selected_delegation[0].code;
                            $scope.paramsdrawitem.color = ($scope.selected_delegation[0].couleur) ? $scope.selected_delegation[0].couleur : '#3388ff';
                            $scope.paramsdrawitem.onEachFeature = function (feature, layer) {
                                console.log("layer", layer);
                                console.log("layer", layer);
                                layer.bindPopup('<pre>' + $scope.selected_delegation[0].id + ' - ' + $scope.selected_delegation[0].libelle + ' - ' + '</pre>');
                                //layer._leaflet_id = $scope.selected_delegation[0].id;
                            }
                            $scope.current_layer = JSON.parse($scope.selected_delegation[0].layer);
                            $scope.current_layer._leaflet_id = $scope.selected_delegation[0].id;
                            $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                            $scope.stock_all_layers.push($scope.drawnItems);
                            $scope.layers_json.push($scope.current_layer);
                            $scope.layers_params.push($scope.paramsdrawitem);


                        }
                    }

                    if (column_name == 'zone') {
                        $scope.current_element = $scope.selected_region[0];
                        $scope.current_libelle = $scope.selected_region[0].libelle;
                        $scope.current_layer = $scope.selected_region[0].layer;
                        $scope.current_coordinates = $scope.selected_region[0].coordinates;
                        // ---------------------------------------------------------------------------------------------------
                        if ($scope.selected_region[0].layer) {

                            $scope.paramsdrawitem.id = $scope.selected_region[0].id;
                            $scope.paramsdrawitem.code = $scope.selected_region[0].code;
                            $scope.paramsdrawitem.color = ($scope.selected_region[0].couleur) ? $scope.selected_region[0].couleur : '#3388ff';
                            $scope.paramsdrawitem.onEachFeature = function (feature, layer) {
                                console.log("layer", layer);
                                console.log("layer", layer);
                                layer.bindPopup('<pre>' + $scope.selected_region[0].id + ' - ' + $scope.selected_region[0].libelle + ' - ' + '</pre>');
                                //layer._leaflet_id = $scope.selected_region[0].id;
                            }
                            $scope.current_layer = JSON.parse($scope.selected_region[0].layer);
                            $scope.current_layer._leaflet_id = $scope.selected_region[0].id;
                            $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                            $scope.stock_all_layers.push($scope.drawnItems);
                            $scope.layers_json.push($scope.current_layer);
                            $scope.layers_params.push($scope.paramsdrawitem);


                        }
                    }

                    $scope.current_element = element;
                    $scope.current_libelle = element.libelle;
                    $scope.current_layer = element.layer ? element.layer : '{}';
                    $scope.current_coordinates = element.coordinates ? element.coordinates : '[]';
                    // ---------------------------------------------------------------------------------------------------
                    //if (element.layer) {
                    // console.log('redraw all layers : ', vm.selected_tournees)
                    $scope.paramsdrawitem = {};
                    $scope.paramsdrawitem.id = element.id ? element.id : null;
                    $scope.paramsdrawitem.code = element.code ? element.code : null;
                    var couleur = 'blue';
                    couleur = column_name == 'delegation' || column_name == 'zone' ? 'red' : couleur;
                    couleur = column_name == 'localite' || column_name == 'secteur' ? 'yellow' : couleur;
                    $scope.paramsdrawitem.color = couleur;
                    $scope.paramsdrawitem.onEachFeature = function (feature, layer) {
                        console.log("layer", layer);
                        console.log("layer", layer);
                        layer.bindPopup('<pre>' + element.id + ' - ' + element.libelle + ' - ' + '</pre>');
                        //layer._leaflet_id = element.id;
                    }


                    if (element.layer) {

                        $scope.current_layer = element.layer ? JSON.parse(element.layer) : {};
                        $scope.current_coordinates = element.coordinates ? JSON.parse(element.coordinates) : '[]';

                        $scope.current_layer.geometry = $scope.current_layer.geometry ? $scope.current_layer.geometry : {};
                        // || (element.libelle=='Sfax' && column_name=="gouvernorat")
                        if (column_name == 'delegation' || column_name == 'localite') {
                            $scope.current_layer.geometry.type = 'Polygon';
                            var temp_coordinates = $scope.current_coordinates[0]
                            //var temp_coordinates = $scope.current_layer.geometry.coordinates[0]
                            $scope.current_layer.geometry.coordinates = temp_coordinates;
                            console.log($scope.current_layer, 'test')
                        }
                        console.log(element.id, element, '$scope.current_layer._leaflet_id')
                        $scope.current_layer = element.layer ? $scope.current_layer : {};
                        console.log('$scope.current_layer', $scope.current_layer);
                        $scope.current_layer._leaflet_id = element.id ? element.id : null;
                        $scope.current_layer = element.layer ? $scope.current_layer : {};
                        console.log($scope.current_layer);



                        $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                        $scope.stock_all_layers.push($scope.drawnItems);
                        $scope.layers_json.push($scope.current_layer);
                        $scope.layers_params.push($scope.paramsdrawitem);

                    } else {
                        $scope.drawnItems = {};
                    }
                    // $scope.drawnItems.eachLayer(function (layer) {
                    //     layer._leaflet_id =  element.id;
                    // });
                    //console.log('Polygon : ', element.id, $scope.drawnItems)
                    // if(index==0){
                    /*  }else{
                         $scope.bounds.extend($scope.drawnItems.getBounds());
                     } */
                    /*  $scope.stock_all_layers.push($scope.drawnItems);
                            $scope.layers_json.push($scope.current_layer);
                            $scope.layers_params.push($scope.paramsdrawitem); */





                    if ($scope.drawnItems) {
                        console.log('add control panel ')
                        $scope.map.addControl(new L.Control.Draw({
                            //edit: force_init == false ? { featureGroup: $scope.drawnItems } : false,
                            edit: element.layer ? { featureGroup: $scope.drawnItems } : false,
                            draw: {
                                polyline: false,
                                polygon: true,
                                /* polygon: {
                                    allowIntersection: false, // Restricts shapes to simple polygons
                                    showArea: true,
                                }, */
                                rectangle: true,
                                marker: false,
                                circlemarker: false,
                                circle: false,
                            }
                        }));
                        console.log('fin control panel ')


                        $scope.map.on(L.Draw.Event.EDITED, function (e) {
                            console.log('-------edited------------');
                            var layers = e.layers;
                            console.log(layers);
                            layers.eachLayer(function (layer) {
                                // Do whatever else you need to. (save to db; add to map etc)
                                // console.log('geometry: ', layer.toGeoJSON().geometry);
                                // console.log('layer.getLatLngs(): ', layer.getLatLngs());

                                //remove previous layer
                                // var area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
                                // console.log('area: ', area);
                                // map.addLayer(layer);
                                console.log('LLLLLAYER', layer)
                                $scope.paramsdrawitem = {};
                                $scope.paramsdrawitem.id = element.id ? element.id : null;
                                $scope.paramsdrawitem.code = element.code ? element.code : null;
                                var couleur = 'blue';
                                couleur = column_name == 'delegation' || column_name == 'zone' ? 'red' : couleur;
                                couleur = column_name == 'localite' || column_name == 'secteur' ? 'yellow' : couleur;
                                $scope.paramsdrawitem.color = couleur;
                                $scope.paramsdrawitem.onEachFeature = function (feature, layer) {
                                    console.log("layer", layer);
                                    console.log("layer", layer);
                                    layer.bindPopup('<pre>' + element.id + ' - ' + element.libelle + ' - ' + '</pre>');
                                    //layer._leaflet_id = element.id;
                                }
                                //issue: Finish drawing process with editable feature
                                var polygon = layer.toGeoJSON().geometry
                                // polygon.properties = $scope.paramsdrawitem;
                                var newLayer = L.GeoJSON.geometryToLayer(polygon, $scope.paramsdrawitem);
                                //console.log('newLayer', newLayer);
                                //newLayer.editing.enable();
                                // $scope.map.addLayer(newLayer);

                                var layer_type = layer.toGeoJSON().geometry.type;
                                if (layer_type == 'circle') {
                                    var raduis = layer.getRadius()
                                } else {
                                    var raduis = '';
                                }
                                var shape = newLayer.toGeoJSON()
                                var shape_for_db = shape;
                                // var shape_for_db = JSON.stringify(shape);
                                //console.log(layer_type, shape_for_db);
                                var data = {
                                    raduis: raduis,
                                    type: layer_type,
                                    layer: shape_for_db,
                                    coordinates: shape_for_db.geometry.coordinates,
                                    code: element.code,
                                    column_name
                                };
                                console.log(data);
                                WS.post('gouvernorats/updateLayers', data)
                                    .then(function (response) {
                                        if (response.data.success == true) {

                                            swal(
                                                'Succès',
                                                column_name + ' crée avec succé',
                                                'success'
                                            );
                                            console.log($scope[column_name + 's'][key], 'before')
                                            $scope[column_name + 's'][key].layer = response.data.layer;
                                            $scope[column_name + 's'][key].coordinates = response.data.coordinates;
                                            $scope[column_name + 's'][key].updated = true;
                                            console.log($scope[column_name + 's'][key], 'after')


                                            $scope.$apply();

                                        } else if (response.data.success == false) {
                                            swal('Error', 'Oups ', 'error');
                                        }
                                    })
                                    .then(null, function (error) {
                                        console.log(error);
                                    });
                            });
                        });


                        //if(column_name == 'gouvernorat' || column_name == 'region' ||column_name ==  'zone' || element.libelle=='secteur'){
                        $scope.map.on(L.Draw.Event.CREATED, function (e) {
                            var type = e.layerType
                                , layer = e.layer;
                            // layer.editing.enable();
                            var newLayer = L.GeoJSON.geometryToLayer(layer.toGeoJSON().geometry);
                            //console.log('newLayer', newLayer);
                            //newLayer.editing.enable();
                            $scope.map.addLayer(newLayer);
                            //$scope.drawnItems.addLayer(layer);
                            if (type == 'circle') {
                                var raduis = layer.getRadius()
                            } else {
                                var raduis = '';
                            }
                            var shape = layer.toGeoJSON()
                            var shape_for_db = shape;

                            var data = {
                                //raduis: raduis,
                                type: type,
                                layer: shape_for_db,
                                coordinates: shape.geometry.coordinates,
                                code: element.code,
                                column_name
                            };
                            console.log(data);

                            WS.post('gouvernorats/updateLayers', data)
                                .then(function (response) {
                                    if (response.data.success == true) {

                                        swal(
                                            'Succès',
                                            column_name + ' crée avec succé',
                                            'success'
                                        );
                                        console.log($scope[column_name + 's'][key], 'before')
                                        $scope[column_name + 's'][key].layer = response.data.layer;
                                        $scope[column_name + 's'][key].coordinates = response.data.coordinates;
                                        $scope[column_name + 's'][key].updated = true;
                                        console.log($scope[column_name + 's'][key], 'after')

                                        // .then(function () {
                                        //     $window.location.reload();
                                        // });
                                        $scope.$apply();

                                    } else if (response.data.success == false) {
                                        swal('Error', 'Oups ', 'error');
                                    }
                                })
                                .then(null, function (error) {
                                    console.log(error);
                                });

                        });
                        //}

                        if (element.code && element.layer) {
                            $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                            $scope.map.fitBounds($scope.bounds);
                        }



                    } else {
                        console.log('drawnItem is empty')
                    }
                    $scope.current_code = element.code;
                    if (column_name != 'tournee' && !tournee) {
                        $scope.getListClientBySearch(column_name, element.code);
                    }
                    console.log('>>>>>>>>>>>>>>>>>>', tournee, $scope.filter_tournee.type_tournee, column_name, '<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<')
                    if (tournee && $scope.filter_tournee.type_tournee == column_name) {
                        $scope.getListClientBySearch(column_name, element.code);
                    }

                    if (column_name == 'tournee') {
                        $scope.getListClientByTournee($scope.current_code);
                    }


                }

                $scope.getListClientBySearch = (column_name, code, sous_column_name = null) => {
                    //  $scope.stock_all_layers = [];
                    $scope.list_clients = [];

                    WS.getData('clients/getListClientBySearch', { column_name, code, sous_column_name , application: $scope.application})
                        .then(function (response) {
                            if (response.status == 200) {
                                // column name = gouvernorat, delegation, localite, region, zone, secteur
                                $scope.nbr_client_out_polygon = 0;
                                $scope.list_clients = response.data;
                                _.each($scope.list_clients, (element, index) => {
                                    $scope.nbr_client_out_polygon += !element.isInPolygon ? 1 : 0;

                                    if (element.latitude && element.longitude) {
                                        //add MARKER
                                        const fontAwesomeIconStart = L.divIcon({
                                            html: '<i class="fa fa-street-view fa-3x"></i>',
                                            iconSize: [15, 15],
                                            className: 'start_position'
                                        });

                                        var myFGMarker = L.FeatureGroup;
                                        var marker = L.marker([element.latitude, element.longitude], { icon: fontAwesomeIconStart });

                                        // markerArray.push(marker);
                                        marker.addTo($scope.map);

                                        $scope.stock_all_markers.push(marker);
                                        var info = $compile(`
                                                            <div id="bodyContent" style="width=300px;">
                                                            <p class="text-center">
                                                                <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                                                    Modifier
                                                                </a>
                                                                <b> | </b>
                                                                <a ui-sref="app.clients.historiques({code : '${element.code}'})" target="_blank">
                                                                Historique
                                                                </a>
                                                            </p>
                                                            <b>Client</b> : ${element.client}</a></br>
                                                            <b>Code</b> : ${element.code}</a></br>
                                                            <b>CAB</b> : ${element.code_a_barre}</br>
                                                            
                                                            </div>
                                                        `)($scope);
                                        var popup = L.popup({
                                            maxWidth: 600,
                                            minWidth: 200
                                        }).setContent(info[0])
                                        marker.bindPopup(popup);
                                    }
                                })

                                console.log($scope.list_clients);
                                // /* if($scope.tab_clients_hors_polygon){
                                //     $scope.nbr_client_out_polygon = Object.keys($scope.tab_clients_hors_polygon).length;

                                //     console.log('nbr_client_out_polygon : ', $scope.nbr_client_out_polygon, $scope.tab_clients_hors_polygon);
                                // } */
                                $scope.$apply();
                            } else { }
                        })
                        .then(null, function (error) {
                            console.log(error);
                        });
                }

                $scope.uploadedFile = function (element) {
                    $scope.currentFile = element.files[0];
                    var reader = new FileReader();
                    reader.onload = function (event) {
                        $scope.file_csv_64 = event.target.result
                        $scope.$apply(function () {
                            $scope.files = element.files;
            
                        });
                    }
                    reader.readAsDataURL(element.files[0]);
                    $scope.$apply();
                };


                $scope.appendPositionsFromFile = ( ) =>  {
                    console.log('file', $scope.currentFile, $scope.files[0], $scope.file_csv_64)
                    WS.postwithimg('resultats_merchs/findGpsFromFile', {file : $scope.currentFile }, "POST")
                    .then(function (response) {
                        if (response.data.success == true) {

                            _.each(response.data.positions, (element, index) => {
 
                                if (element.latitude && element.longitude) {
                                    //add MARKER
                                    const fontAwesomeIconStart = L.divIcon({
                                        html: '<i class="fa fa-street-view fa-3x"></i>',
                                        iconSize: [10, 10],
                                        className: 'rs_imported_positions'
                                    });

                                    var myFGMarker = L.FeatureGroup;
                                    var marker = L.marker([element.latitude, element.longitude], { icon: fontAwesomeIconStart });

                                    // markerArray.push(marker);
                                    marker.addTo($scope.map);

                                    //$scope.stock_all_markers.push(marker);
                                    // var info = $compile(`
                                    //                     <div id="bodyContent" style="width=300px;">
                                    //                     <p class="text-center">
                                    //                         <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                    //                             Modifier
                                    //                         </a>
                                    //                         <b> | </b>
                                    //                         <a ui-sref="app.clients.historiques({code : '${element.code}'})" target="_blank">
                                    //                         Historique
                                    //                         </a>
                                    //                     </p>
                                    //                     <b>Client</b> : ${element.client}</a></br>
                                    //                     <b>Code</b> : ${element.code}</a></br>
                                    //                     <b>CAB</b> : ${element.code_a_barre}</br>
                                                        
                                    //                     </div>
                                    //                 `)($scope);
                                    // var popup = L.popup({
                                    //     maxWidth: 600,
                                    //     minWidth: 200
                                    // }).setContent(info[0])
                                    // marker.bindPopup(popup);
                                }
                            })


                            swal(
                                $translate.instant('Succé'),
                                $translate.instant(response.data.message),
                                'success'
                            );
                        } else {
                            swal(
                                $translate.instant('Warning'),
                                $translate.instant(response.data.message),
                                'warning'
                            );
                        }
                    }, function (error) {
                        console.log(error);
                    });
                }

                $scope.getListClientByTournee = (tournee_code) => {
                    $scope.list_clients_tournee = [];
                    WS.getData('clients/getListClientByTournee', { tournee_code })
                        .then(function (response) {
                            if (response.status == 200) {
                                // column name = gouvernorat, delegation, localite, region, zone, secteur
                                $scope.nbr_client_out_polygon_tournee = 0;
                                $scope.list_clients_tournee = response.data;
                                _.each($scope.list_clients_tournee, (element, index) => {
                                    $scope.nbr_client_out_polygon_tournee += !element.isInPolygon ? 1 : 0;

                                    if (element.latitude && element.longitude) {
                                        //add MARKER
                                        const fontAwesomeIconStart = L.divIcon({
                                            html: '<i class="fa fa-street-view fa-3x"></i>',
                                            iconSize: [15, 15],
                                            className: 'start_position'
                                        });

                                        var myFGMarker = L.FeatureGroup;
                                        var marker = L.marker([element.latitude, element.longitude], { icon: fontAwesomeIconStart });

                                        // markerArray.push(marker);
                                        marker.addTo($scope.map);

                                        $scope.stock_all_markers.push(marker);
                                        var info = $compile(`
                                                            <div id="bodyContent" style="width=300px;">
                                                            <p class="text-center">
                                                                <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                                                    Modifier
                                                                </a>
                                                                <b> | </b>
                                                                <a ui-sref="app.clients.historiques({code : '${element.code}'})" target="_blank">
                                                                Historique
                                                                </a>
                                                            </p>
                                                            <b>Client</b> : ${element.client}</a></br>
                                                            <b>Code</b> : ${element.code}</a></br>
                                                            <b>CAB</b> : ${element.code_a_barre}</br>
                                                            
                                                            </div>
                                                        `)($scope);
                                        var popup = L.popup({
                                            maxWidth: 600,
                                            minWidth: 200
                                        }).setContent(info[0])
                                        marker.bindPopup(popup);
                                    }
                                })

                                //console.log($scope.list_clients);
                                /* if($scope.tab_clients_hors_polygon){
                                    $scope.nbr_client_out_polygon = Object.keys($scope.tab_clients_hors_polygon).length;

                                    console.log('nbr_client_out_polygon : ', $scope.nbr_client_out_polygon, $scope.tab_clients_hors_polygon);
                                } */
                                $scope.$apply();
                            } else { }
                        })
                        .then(null, function (error) {
                            console.log(error);
                        });
                }

                $scope.updateClientHorsPolygon = (type = 'gouvernorat') => {
                    var clients = $scope.list_clients.filter(client => client.selected == true);
                    //console.log('clients' , clients)
                    WS.post('clients/updateClientHorsPolygon', {  application : $scope.application ,  clients})
                        .then(function (response) {
                            //$scope.routeswithdepot = response.data;
                            if (response.data.success) {
                                swal('succés', 'Action effectué avec succés', 'success');
                                var codes = _.pluck(clients, 'code');
                                if (codes.length > 0) {
                                    $scope.list_clients = $scope.list_clients.filter(client => !codes.includes(client.code))

                                    switch (type) {
                                        case 'gouvernorat':
                                            $scope.selectGouvernorats();
                                            break;
                                        case 'delegation':
                                            $scope.selectDelegationByGouvernorat();
                                            break;
                                        case 'zone':
                                            $scope.selectZonesByRegion();
                                            break;
                                        default:
                                            break;
                                    }

                                }
                            }
                            $scope.$apply();
                        })
                        .then(null, function (error) {
                            console.log(error);
                        });


                }

                /************************************************************************************************************************************** */
                /**************************************************************Routings**************************************************************** */
                /************************************************************************************************************************************** */
                /**
                 * @desc Routings
                 */

                $scope.selectRoutes = function (withMaps = true) {
                    //console.log('routes : ')
                    $scope.list_clients = [];
                    WS.getData('routing/selectRoutes')
                        .then(function (response) {
                            $scope.list_routings = response.data;
                            $scope.$apply();
                        })
                        .then(null, function (error) {
                            console.log(error);
                        });
                };

                $scope.getRouteWithDepot = function () {
                    WS.getData('routing/getRouteWithDepot')
                        .then(function (response) {
                            $scope.routeswithdepot = response.data;
                            console.log($scope.routeswithdepot);
                            $scope.$apply();
                            /*
                            $('.table_routes').DataTable({
                                "language": DatatableLang
                            });
                            */
                        })
                        .then(null, function (error) {
                            console.log(error);
                        });
                };

                /************************************************************************************************************************************** */
                /**************************************************************Tournees**************************************************************** */
                /************************************************************************************************************************************** */

                /**
                 * @desc Tournees
                 */

                $scope.selectUsers = () => {
                    WS.getData('users/selectUsers')
                        .then(function (response) {
                            $scope.list_depots = response.data;
                            $scope.$apply();
                        })
                        .then(null, function (error) {
                            console.log(error);
                        });
                }

                $scope.initMapsTournees = () => {
                    console.log('init initMapsTournees')
                    $scope.current = false;
                    $scope.current_code = null;
                    $scope.current_libelle = '';
                    $scope.center_latitude = vm.mapstunisie_latitude;
                    $scope.center_longitude = vm.mapstunisie_longitude;
                    $('#container_tournees').empty();
                    $('#container_tournees').append('<div style="width: 100%; height: 640px" id="maps_tournees"></div>');

                    $scope.osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
                    $scope.osmAttrib = '&copy; <a href="http://nomadis.online/">Nomadis</a>';
                    $scope.osm = L.tileLayer($scope.osmUrl, {
                        maxZoom: 18,
                        attribution: $scope.osmAttrib
                    });
                    //console.log('from maps : ', $scope.getpays)
                    $scope.map = new L.Map('maps_tournees', {
                        center: new L.LatLng($scope.center_latitude, $scope.center_longitude),
                        zoom: 11,
                        fullscreenControl: true,
                        fullscreenControlOptions: {
                            position: 'topright'
                        }
                    });

                    $scope.osm.addTo($scope.map);
                    $scope.clearLayers();
                    //add poligones : 
                    var count_layer = 0;
                    _.each($scope.tourneesClient.data, (element, index) => {
                        //console.log(element);
                        if (element.code != null && element.layer != null) {
                            count_layer++;
                            $scope.paramsdrawitem = {};
                            $scope.paramsdrawitem.id = element.id;
                            $scope.paramsdrawitem.code = element.code;
                            $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : '#3388ff';
                            $scope.paramsdrawitem.onEachFeature = function (feature, layer) {
                                layer.bindPopup('<pre>' + element.code + '</pre>');
                                //layer._leaflet_id = element.id;
                            }
                            $scope.current_layer = JSON.parse(element.layer);
                            // $scope.current_layer._leaflet_id = element.id;
                            $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                            $scope.stock_all_layers.push($scope.drawnItems);
                            $scope.layers_json.push($scope.current_layer);
                            $scope.layers_params.push($scope.paramsdrawitem);


                            if (!$scope.bounds) {
                                $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                            } else {
                                $scope.bounds.extend($scope.drawnItems.getBounds());
                            }
                        }
                    })
                    if (count_layer > 0) {
                        $scope.map.fitBounds($scope.bounds);
                    }


                    //add panel control
                    $scope.map.addControl(new L.Control.Draw({
                        //edit: force_init == false ? { featureGroup: $scope.drawnItems } : false,
                        edit: false,
                        draw: {
                            polyline: false,
                            polygon: true,
                            /* polygon: {
                                allowIntersection: false, // Restricts shapes to simple polygons
                                showArea: true,
                            }, */
                            rectangle: true,
                            marker: false,
                            circlemarker: false,
                            circle: false,
                        }
                    }));
                    $scope.map.on(L.Draw.Event.CREATED, function (e) {
                        var type = e.layerType
                            , layer = e.layer;
                        // layer.editing.enable();
                        var newLayer = L.GeoJSON.geometryToLayer(layer.toGeoJSON().geometry);
                        //console.log('newLayer', newLayer);
                        //newLayer.editing.enable();
                        $scope.map.addLayer(newLayer);
                        //$scope.drawnItems.addLayer(layer);
                        if (type == 'circle') {
                            var raduis = layer.getRadius()
                        } else {
                            var raduis = '';
                        }
                        var shape = layer.toGeoJSON()
                        var shape_for_db = shape;

                        var data = {
                            //raduis: raduis,
                            type: type,
                            layer: shape_for_db,
                            coordinates: shape.geometry.coordinates,
                            // code: element.code,
                            column_name: 'tournee'
                        };
                        console.log(data);

                        vm.openModalCreateTournee(data, $scope.list_depots, $scope.list_routings, $scope.list_clients, $scope.map);

                    });

                    setTimeout(function () {
                        $scope.map.invalidateSize();
                        console.log('initialized');
                    }, 3000);
                }

                $scope.getTourneesClient = function (page = 1, per_page = 20) {
                    $scope.list_clients = [];
                    var data = {
                        page,
                        per_page,
                        routing_code: $scope.filter_tournee.routing_code,
                        user_code: $scope.filter_tournee.user_code,
                        depot_code: $scope.filter_tournee.depot_code,
                        code_jour: $scope.filter_tournee.code_jour,
                        frequence: $scope.filter_tournee.frequence
                    };
                    WS.getData('tournees', data)
                        .then(function (response) {
                            if (response.status == 200) {
                                $scope.tourneesClient = response.data;
                                $scope.tourneesClient.next = $scope.tourneesClient.current_page + 1;
                                $scope.tourneesClient.previous = $scope.tourneesClient.current_page - 1;
                                $scope.tournee_paginate = [];
                                for (var i = 1; i <= $scope.tourneesClient.last_page; i++) {
                                    $scope.tournee_paginate.push(i);
                                }
                                $scope.$apply();
                                $scope.initMapsTournees();
                                /*$('.tabletournees').DataTable({
                                    "language": DatatableLang
                                });*/
                            } else { }
                        })
                        .then(null, function (error) {
                            console.log(error);
                        });
                };

                $scope.checkpaginate = function (current, page) {
                    if (page > current + 3 || page < current - 3) {
                        return false;
                    } else {
                        return true;
                    }
                }

            }
        });
    }


    vm.openModalCreateTournee = (data, list_depots, list_routings, list_clients, map) => {
        console.log('openModalCreateTournee')
        vm.current_layer_value = data;
        vm.list_depots = list_depots;
        vm.list_routings = list_routings;
        vm.list_clients = list_clients;
        vm.modalAddTournee = $uibModal.open({
            animation: true,
            templateUrl: 'add_new_tournee_form',
            size: 'md',
            backdrop: false,
            windowClass: 'z-index-sec-modal',
            controller: function ($scope, $translate) {
                $scope.message = $translate.instant("Ajouter tournee : ");
                $scope.layer = vm.current_layer_value;
                $scope.new_tournee = {};
                $scope.list_depots = vm.list_depots;
                $scope.list_routings = vm.list_routings;
                $scope.list_clients = vm.list_clients;
                $scope.storeTournee = function () {
                    var data = {
                        routing_code: $scope.new_tournee.routing_code,
                        code: $scope.new_tournee.code,
                        depot_code: $scope.new_tournee.depot_code,
                        user_code: $scope.new_tournee.user_code,
                        frequence: $scope.new_tournee.frequence,
                        code_jour: $scope.new_tournee.code_jour,
                        date_debut: $scope.new_tournee.date_debut,
                        date_fin: $scope.new_tournee.date_fin,
                        couleur: $scope.new_tournee.couleur,

                        type: $scope.layer.type,
                        layer: $scope.layer.layer,
                        coordinates: $scope.layer.coordinates,
                        column_name: $scope.layer.column_name,
                        clients: $scope.list_clients
                    }

                    console.log('------------------------ fin resultat -----------------------');
                    console.log(data);
                    console.log('------------------------ fin resultat -----------------------');
                    WS.post('routing/addNewTourneePolygon', data)
                        .then(function (response) {
                            if (response.status == 200) {
                                vm.modalAddTournee.close();
                                swal(
                                    'Succès',
                                    'tournee crée avec succé',
                                    'success'
                                );
                                $scope.$apply();
                            } else { }
                        })
                        .then(null, function (error) {
                            console.log(error);
                        });
                }

                $scope.closeModal = () => {
                    // $scope.initMapsTournees();
                    vm.modalAddTournee.close();
                    map.removeLayer($scope.layer.layer);

                }



            }
        });
    }

    vm.insidePoligon = (point, vs, multipolygon = false) => {
        // ray-casting algorithm based on
        // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

        var x = point[0],
            y = point[1];

        var inside = false;
        for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
            var xi = vs[i][0],
                yi = vs[i][1];
            var xj = vs[j][0],
                yj = vs[j][1];

            var intersect = ((yi > y) != (yj > y)) &&
                (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }

        return inside;
    };

    if (AppSettings.module == "Recensement") {
        //make a function global and call it in any controller
        $rootScope.$on("methodOpenModalZonning", function () {
            vm.openModalZonning();
        });
        $(document).ready(function () {
            $('#btn_openModalZonning').on("click", function (e) {
                vm.openModalZonning();

            });


            $('#btn_openModalZonning').trigger("click");
        });

        $('#btn_openModalZonning').trigger("click");
    }


    /**
     * show interface login with valid credencials
     */



    vm.inviteToLogin = () => {
        $localStorage.inviteToLogin = true;
        vm.inviteToLoginInterface = $uibModal.open({
            animation: true,
            templateUrl: 'inviteToLogin',
            size: 'lg',
            backdrop: false,
            windowClass: 'modal-inviteToLOgin',
            controller: function ($scope, $translate) {

                $scope.closeInviteToLogin = () => {
                    console.log('panel_closed')
                    $localStorage.inviteToLogin = false;
                    vm.inviteToLoginInterface.close();
                    setTimeout(function () {
                        console.log('inviteToLogin 1', $localStorage.inviteToLogin)
                        if ($localStorage.user) {
                            if (!$localStorage.inviteToLogin || vm.user.email_verifie == 0) {
                                console.log('inviteToLogin 2', $localStorage.inviteToLogin)
                                console.log('token de validation email : ', $location.search().token_validation_nomadis)
                                vm.inviteToLogin();

                            }
                        }

                    }, 5000);
                }

                $scope.submitSendMail = () => {
                    console.log($scope.email_valid);
                    WS.post('activateEmail', { email: $scope.email_valid }).then(
                        (response) => {
                            toastr.success($translate.instant('Email envoyé avec succés'));
                            $scope.closeInviteToLogin();
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }

            }
        });
    }


    /* setTimeout(function(){ 
        console.log('inviteToLogin 1', $localStorage.inviteToLogin)
        if($localStorage.user){
            if(!$localStorage.inviteToLogin || vm.user.email_verifie==0) {
                console.log('inviteToLogin 2', $localStorage.inviteToLogin)
                console.log('token de validation email : ', $location.search().token_validation_nomadis);
                vm.inviteToLogin();
            }
        }
        
    }, 5000); */



}
export default {
    name: 'globalController',
    fn: globalController
};